import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Split Jerk 4×4\\@75% 1RM`}</p>
    <p>{`Band Pull Aparts 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time:`}</p>
    <p>{`15/12 Calorie Assault Bike`}</p>
    <p>{`12 Push Press (95/65)(RX+ 115/75)`}</p>
    <p>{`9 Pullups (RX+ CTB Pullups)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      